import React from 'react';

import PageLayout from '../layouts/page/page.layout';

import { useIsMobile } from '../utils/hooks/use-is-mobile';

import "/static/css/index.css"


const ProcessingPage = () => {
	const isMobile = useIsMobile();

	return (
		<div className='blue-bg'>

				{ isMobile && 
					<div id="simple-spinner"> </div>
				}
				

				{ !isMobile && 
					<>
						<div id="simple-spinner"> </div>
					</>
				}

		</div>
	);
};

export default ProcessingPage;
